<template>
  <div class="booking_info">
    <div class="head">Booking Confirmation</div>
    <div class="body">
      <div class="inner">
        <div v-if="bookingLatest.updateUserIcon" class="user">
          <figure class="avatar table"><img :src="bookingLatest.updateUserIcon"></figure>
          <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
            <div slot="content">{{bookingLatest.updateUserName}}</div>
            <div class="from_name">{{bookingLatest.updateUserName}}</div>
          </el-tooltip>
        </div>
        <div class="info">
          <div v-if="bookingLatest.bookingStatus" class="badge">
            <span>{{getBookingStatus(bookingLatest.bookingStatus)}}</span>
          </div>
          <div class="detail">
            <div class="detail_item">
              <span class="key_name">Vessel Booking No : </span>
              <span class="value">{{vesselBookingNo}}</span>
            </div>
            <div class="detail_item">
              <span class="key_name">Carrier Name : </span>
              <span class="value">{{carrierName}}</span>
            </div>
            <div class="detail_item">
              <span class="key_name">Vessel Name / Voyage No : </span>
              <span class="value">{{nameOfVessel}}<span v-show="voyageNo"> / </span>{{voyageNo}}</span>
            </div>
            <div class="detail_item">
              <span class="key_name">Loading Port ETD : </span>
              <span class="value">{{loadingPortEtd}}</span>
            </div>
            <div class="detail_item">
              <span class="key_name">Loading Name : </span>
              <span class="value">{{portOfLoadingName}}<span v-show="countryOfLoadingName">, </span>{{countryOfLoadingName}}</span>
            </div>
            <div class="detail_item">
              <span class="key_name">Discharging Port ETA : </span>
              <span class="value">{{dischargingEta}}</span>
            </div>
            <div class="detail_item">
              <span class="key_name">Discharging Name : </span>
              <span class="value">{{portOfDischargingName}}<span v-show="countryOfDischargingName">, </span>{{countryOfDischargingName}}</span>
            </div>
            <div v-if="bookingLatest.canCancel && isFromPic" class="cancel">
              <el-checkbox v-model="bookingCancelFlgModel" border class="border_none" :true-label="BOOKING_CANCEL_FLG.ON" :false-label="BOOKING_CANCEL_FLG.OFF">Cancel Request</el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { BOOKING_STATUS_VARIABLES, BOOKING_CANCEL_FLG } from 'lib-tw-common';

export default {
  name: 'TwBookingLinking',
  components: {},
  props: {
    linkageTransportation: {
      type: Object,
      default: () => {}
    },
    bookingLatest: {
      type: Object,
      default: () => {}
    },
    isFromPic: {
      type: Boolean,
      default: false
    },
    bookingCancelFlg: {
      type: Number,
    },
  },
  data() {
    return {
      bookingCancelFlgModel: BOOKING_CANCEL_FLG.OFF,
      isMounted: false,
      BOOKING_CANCEL_FLG: BOOKING_CANCEL_FLG,
      // スタブ
      // bookingLatest: {
      //   'shipmentId': '1234',
      //   'inttraReferenceId': '1234',
      //   'bookingStatus': 2,
      //   'bookingState': 'Confirmed',
      //   'canRequest': false,
      //   'canCancel': true,
      //   'createUserId': '1234',
      //   'createUserName': 'createUserName',
      //   'createUserIcon': '',
      //   'createDate': '2022-08-22',
      //   'updateUserId': '1234',
      //   'updateUserName': 'updateUserName',
      //   'updateUserIcon': '',
      //   'updateDate': '2022-08-22',
      // },
    };
  },
  computed: {
    vesselBookingNo() {
      return _.get(this.linkageTransportation, 'containerGrp.vesselBookingNo')
    },
    carrierName() {
      return _.get(this.linkageTransportation, 'carrierGrp.carrierName')
    },
    nameOfVessel() {
      return _.get(this.linkageTransportation, 'logisticsLoadingGrp.nameOfVessel')
    },
    voyageNo() {
      return _.get(this.linkageTransportation, 'logisticsLoadingGrp.voyageNo')
    },
    loadingPortEtd() {
      return _.get(this.linkageTransportation, 'logisticsLoadingGrp.loadingPortEtd')
    },
    portOfLoadingName() {
      return _.get(this.linkageTransportation, 'logisticsLoadingGrp.portOfLoadingName')
    },
    countryOfLoadingName() {
      return _.get(this.linkageTransportation, 'logisticsLoadingGrp.countryOfLoadingName')
    },
    dischargingEta() {
      return _.get(this.linkageTransportation, 'logisticsDischargingGrp.dischargingEta')
    },
    portOfDischargingName() {
      return _.get(this.linkageTransportation, 'logisticsDischargingGrp.portOfDischargingName')
    },
    countryOfDischargingName() {
      return _.get(this.linkageTransportation, 'logisticsDischargingGrp.countryOfDischargingName')
    },
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.isMounted = true;
    }, 20);
  },
  watch: {
    bookingCancelFlgModel(val) {
      if (this.isMounted && val) {
        this.$store.dispatch('SHOW_ALERT', 'Are you sure you want to proceed?<br />Once approved, INTTRA will be notified of the cancellation. After Withdraw, the status of the process becomes Input in Progress.');
        this.$emit('onCheckedBookingCancelFlg', val);
        // this.$store
        //   .dispatch('SHOW_CONFIRM', 'キャンセル仮文言')
        //   .then(() => {
        //     this.$emit('onCheckedBookingCancelFlg', val);
        //   })
        //   .catch(() => {
        //     this.bookingCancelFlgModel = false;
        //   });
      } else this.$emit('onCheckedBookingCancelFlg', val);
    },
  },
  methods: {
    getBookingStatus(val) {
      const target = BOOKING_STATUS_VARIABLES.find(o => o.code === val);
      return target ? target.label : '';
    },
  }
};
</script>

<style lang="scss" scoped>
.booking_info {
  display: flex;
  // align-items: center;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
  color: $color_gray_800;
  .head {
    display: flex;
    align-items: center;
    background: $color_gray_300;
    border-radius: 6px 0px 0px 6px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    padding: 0 16px;
    width: 140px;
  }
  .body {
    background: #FFFFFF;
    border: 1px solid $color_gray_300;
    border-radius: 0px 6px 6px 0px;
    width: 100%;
  }
  .inner {
    display: flex;
    padding: 20px;
    height: auto;
    .user {
      display: flex;
      margin-right: 32px;
      font-size: 14px;
      line-height: 20px;
      color: $color_black;
      .from_name {
        width: 120px;
        height: 24px;
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .info {
      display: flex;
      .badge {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        left: 0px;
        top: 0px;
        padding: 4px 8px;
        background: #0500FF;
        color: $color_white;
        border-radius: 15.5px;
        font-size: 12px;
        font-weight: bold;
        margin: 2px 24px 0 0;
      }
    }
    .detail {
      .detail_item {
        display: flex;
        margin-bottom: 4px;
      }
      .key_name {
        min-width: 160px;
        width: 160px;
        font-size: 12px;
        // color: #9191A4;
      }
      .value {
        font-size: 14px;
        color: #3E3A39;
      }
      .cancel {
        margin-top: 8px;
      }
    }
  }
  ::v-deep .el-checkbox .el-checkbox__label {
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
